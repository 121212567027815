body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  padding: 20px;
  min-height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

.page.white {
  background-color: #ffffff;
  color: #000000;
}

.page.dark {
  background-color: #333333;
  color: #ffffff;
}

.light {
  background-color: white;
  color: black;
}

.dark {
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 768px) {
  .rounded-lg {
    padding: 1rem; /* 调整内边距 */
  }
}

.highlighted-text {
  background-color: rgba(255, 255, 0, 0.3);
  cursor: pointer;
}

.highlighted-text:hover {
  background-color: rgba(255, 255, 0, 0.5);
}

/* 如果需要自定义滑出侧边栏的动画效果 */
.transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
